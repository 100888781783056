import Rooms from "@/views/rooms.vue";

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Site" */ '@/views/home.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "About" */ '@/views/about.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact.vue'),
    },
    {
        path: '/events',
        name: 'Events',
        component: () => import(/* webpackChunkName: "Events" */ '@/views/events.vue'),
    }, {
        path: '/cbi',
        name: 'Cbi',
        component: () => import(/* webpackChunkName: "Events" */ '@/views/cbi.vue'),
    },
    {
        path: '/rooms',
        name: 'Rooms',
        component: Rooms,
        // component: () => import(/* webpackChunkName: "Rooms" */ '@/views/rooms.vue'),
    },
    {
        path: '/rooms/:room_name',
        name: 'Room',
        component: () => import(/* webpackChunkName: "Room" */ "@/views/room.vue"),
        props: true
    },
    {
        path: '/booking',
        name: 'Booking',

        component: () => import(/* webpackChunkName: "OpenHotel" */ "@/views/booking.vue"),
    },
    {
        path: '/travel-insurance',
        name: 'TravelInsurance',
        component: () => import(/* webpackChunkName: "OpenHotel" */ "@/views/TravelInsurance.vue"),
    },

]