<template>
    <div>
        <v-col style="background-color: lightgray">
            <v-row align="center" dense class="pb-10">
                <v-col :cols="8" :offset="2" :md="12" :offset-md="0" :lg="3" align="center">
                    <!-- Left side -->
                    <v-img
                        min-width="40%"
                        max-width="500px"
                        :max-height="$vuetify.breakpoint.lgAndUp?'350px': '150px'"
                        :src="req('logo/logo_with_text_white_background.svg')"/>
                </v-col>
                <v-col
                    :xs="12" :offset="0"
                    :sm="10" :offset-sm="1"
                    :md="10" :offset-md="1"
                    :lg="8" :offset-lg="0"
                >
                    <v-row justify="center" :align="$vuetify.breakpoint.lgAndUp?'center': 'start'">
                        <v-col :cols="11" :offset="1" :md="3" :offset-md="0" :lg="3">
                            <p class="font-weight-black one_line">
                                BAY HOUSE <br>
                                Boutique Hotel
                            </p><br>
                            <p>
                                Grand Anse <br>
                                Grenada, West Indies
                            </p>
                        </v-col>
                        <v-col :cols="11" :offset="1" :md="6" :offset-md="0" :lg="5">
                            <contact_details/>
                        </v-col>
                        <v-col lg="4" class="d-flex justify-space-around mt-3">
                            <facebook/>
                            <instagram/>
                            <linkedin/>
                            <v-btn
                                text
                                :to="{'name':'TravelInsurance'}"
                            >
                                Travel Insurance
                            </v-btn>

                        </v-col>
                    </v-row>

                </v-col>
            </v-row>

        </v-col>
    </div>
</template>

<script>
import {image_mixin} from "@/mixins/image_mixin";
import Spacing from "@/shared/components/helper/Spacing.vue";
import {contact_data} from "@/views/data/contact";
import IndeImg from "@/views/components/IndeImg.vue";
import Contact_details from "@/views/components/TheFooter/contact_details.vue";
import Instagram from "@/views/components/TheFooter/instagram.vue";
import Facebook from "@/views/components/TheFooter/facebook.vue";
import Linkedin from "@/views/components/TheFooter/linkedin.vue";

export default {
    name: "TheFooter",
    components: {Linkedin, Facebook, Instagram, Contact_details, IndeImg, Spacing},
    mixins: [image_mixin],
    data() {
        return {
            contact_data
        }
    }
}
</script>

<style scoped>
.one_line {
    display: inline;
}
</style>